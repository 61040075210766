
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//icecartel.com/cdn/fonts/montserrat/montserrat_n4.1d581f6d4bf1a97f4cbc0b88b933bc136d38d178.woff2?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=2764daf2b8000e0d89a7b63f54856312451de76386f5ce8f95b6c51d693a81b5") format("woff2"),
       url("//icecartel.com/cdn/fonts/montserrat/montserrat_n4.cfce41a967758ce5a9b7d48daeb5b028fd977a9b.woff?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=89e7fa162f514b4a9f39922d1e02008d3dc895288a891c9fc16fd12dfbfa65d4") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//icecartel.com/cdn/fonts/montserrat/montserrat_n7.c496e9cf2031deec4c4bca338faa81971c8631d4.woff2?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=999228b261f66328cfae43918903f8257bf1fbd2b9607e877c2b0626d2ac23f0") format("woff2"),
       url("//icecartel.com/cdn/fonts/montserrat/montserrat_n7.78b0223375c94b39ce1af7e09a0225f2bb3d05f7.woff?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=340482601a99578d86b027d644045261bd3ba6bfaca5805eacfa6e686270b4ae") format("woff");
}


@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("//icecartel.com/cdn/fonts/montserrat/montserrat_i7.83866c3eec90071fa974c17980ffb42977f9e667.woff2?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=cbf5f29e19d146c78ae28a875f1dacad37ee5da528c872822f4bfe6a1f03fade") format("woff2"),
       url("//icecartel.com/cdn/fonts/montserrat/montserrat_i7.25524241b12d864609c85325613d60efcf1a87e3.woff?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=aab7a74b4ff39ff65c8a4b93017d6ea9a7749a47537c65c40ef78ed9a8283a92") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//icecartel.com/cdn/fonts/montserrat/montserrat_n4.1d581f6d4bf1a97f4cbc0b88b933bc136d38d178.woff2?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=2764daf2b8000e0d89a7b63f54856312451de76386f5ce8f95b6c51d693a81b5") format("woff2"),
       url("//icecartel.com/cdn/fonts/montserrat/montserrat_n4.cfce41a967758ce5a9b7d48daeb5b028fd977a9b.woff?h1=aWNlY2FydGVsLmNvbQ&h2=aWNldWstY29tLmFjY291bnQubXlzaG9waWZ5LmNvbQ&hmac=89e7fa162f514b4a9f39922d1e02008d3dc895288a891c9fc16fd12dfbfa65d4") format("woff");
}


@font-face {
  font-family: "Montserrat";
  src: url("https://cdn.shopify.com/s/files/1/0679/4461/2161/files/Montserrat.woff2?v=1686661756") format("woff2"),
  url("https://cdn.shopify.com/s/files/1/0679/4461/2161/files/Montserrat.woff2?v=1686661756") format("woff");
  font-display: swap;
}

:root {
  --gray: #ececec;
  --dark-gray: #747474;
  --green:#6cad61;
  --red:#ec7c7c;
  --heading-font-family:Montserrat,sans-serif;
  --heading-font-family: "Montserrat";
  --heading-font-weight:400;
  --heading-font-weight:500;
  --font-family:Montserrat,sans-serif;
  --font-family: "Montserrat";
  --body-font-weight:400;
  --body-font-weight:400;
  --thin:100;
  --light:300;
  --normal:400;
  --medium:500;
  --semibold:600;
  --bold:700; 
  --extrabold:900;
  --theme-color: #12608c;
  --theme-light-color: rgba(18, 96, 140, 0.1);
  --colorTextBody: #000000;
  --colorLightTextBody: rgba(0, 0, 0, 0.5);
  --colorXlightTextBody: rgba(0, 0, 0, 0.3);
  --colorXXlightTextBody: rgba(0, 0, 0, 0.1);
  --cartDotColor: #42b0f0;
  --cartDotColorLight:rgba(66, 176, 240, 0.3);
  --cartDotColorNull:rgba(66, 176, 240, 0);
  --colorBtnPrimary: #ffffff;
  --colorBtnPrimaryText: #000000; 
  --colorBtnPrimaryBorder: #000000; 
  --colorBtnSecondary: #ffffff;
  --colorBtnSecondaryText:#000000;
  --colorBtnSecondaryBorder:#000000;  
  --primaryColorBody: #ffffff;
  --secondaryColorBody: #f7f7f7;
  
--primarySocialIconBg:#e6e6e6;
    --primaryAlternateBg:#f2f2f2;--secondarySocialIconBg:#dedede;--tertiarySocialIconBg:#153854;--quaternarySocialIconBg:#12423d;--secondarycolorTextBody: #000000;
  --secondaryColorLightTextBody: rgba(0, 0, 0, 0.5);
  --secondaryColorXlightTextBody: rgba(0, 0, 0, 0.3);
  --secondary_heading_color: #000000;
  --tertiaryColorBody:#0b1d2b;
  --tertiarycolorTextBody: #ffffff;
  --tertiaryColorLightTextBody: rgba(255, 255, 255, 0.5);
  --tertiaryColorXlightTextBody: rgba(255, 255, 255, 0.3);
  --tertiary_heading_color: #ffffff;
  --gradientColorBody: linear-gradient(90deg, rgba(255, 42, 0, 1), rgba(204, 146, 146, 1) 100%);
  --quaternaryColorBody: #071a18;
  --quaternarycolorTextBody: #181818;
  --quaternaryColorLightTextBody: rgba(24, 24, 24, 0.5);
  --quaternaryColorXlightTextBody: rgba(24, 24, 24, 0.3);
  --quaternary_heading_color: #181818;
  --colorImageBg: #f3efec;
  --colorImageBorder: #eee;
  --productImageBorder:0;
  --articleImageBorder:0;
  --collectionImageBorder:0;
  --colorTextSavings: #E22120;
  --colorBgSavings: #ffd1d1;
  --heading_color: #0c1315;
  --heading_base_size: 0.89;
  --heading_spacing: 0.0em;
  --heading_line_height: 1.1;
  --base_spacing: 0.0em;
  --base_line_height: 1.6;
  --text_base_size: 1.0;
  --buttonRadius: 0px;
  --productGridRadius: 20px;
  --headingTextTransform:uppercase;
  --buttonFontFamily: var(--heading-font-family);
  --variantFontFamily: var(--font-family);
  --filtersHeadingFontFamily: var(--font-family);
  --priceFontFamily: var(--font-family);
  --tabHeadingFontFamily: var(--font-family);
  --tabHeadingTextTransform:capitalize;
  --navigationFontFamily: "Montserrat";
/*   --navigationFontFamily: var(--heading-font-family); */
  --navigationTextTransform:uppercase;
  --variantTextTransform:uppercase;
  --xsmallText:calc(var(--text_base_size) * 10px);
  --smallText: calc(var(--text_base_size) * 12px);
  --text: calc(var(--text_base_size) * 14px);
  --mediumText: calc(var(--text_base_size) * 16px);
  --largeText: calc(var(--text_base_size) * 18px);
  --xlargeText:  calc(var(--text_base_size) * 20px);
  --buttonAnimationDirection:translateX(-100%);
}
@media (min-width:1025px) {
  .button:before,
  .pdp-action-container button.shopify-payment-button__button--hidden:before,
  .pdp-action-container button.shopify-payment-button__button:before,
  .tertiary-button:before {
      content: "";
      position: absolute;top:0;
        right:0;
        width:0;
        height:100%;z-index: -1;
      transition: 0.6s ease-in-out;
  }
  .button:hover:before,
  .pdp-action-container button.shopify-payment-button__button--hidden:hover:before,
  .pdp-action-container button.shopify-payment-button__button:hover:before,
  .secondary-button:hover:before {right:auto;
      left:0;
      width:100%;}
}
  